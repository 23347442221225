import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import R, { size } from "../R";

const AnimationContainer = styled(Grid)({
  marginTop: size.margin.large,
});

const AnimationDot = styled(motion.div)({
  width: 14,
  height: 14,
  borderRadius: 10,
  marginRight: 5,
});

const animationProps = {
  initial: {
    translateY: 0,
  },
  animate: {
    translateY: [0, 15, 0, -15, 0],
  },
};

export const bonusXLoadingId = "loading-indicator";

export default function BonusXLoading() {
  return (
    <AnimationContainer data-testid={bonusXLoadingId} container justifyContent="center">
      <AnimationDot
        {...animationProps}
        transition={{ repeat: Infinity, delay: 0, type: "keyframes" }}
        css={{ backgroundColor: R.colors.vividGreen }}
      />
      <AnimationDot
        {...animationProps}
        transition={{ repeat: Infinity, delay: 0.25, type: "keyframes" }}
        css={{ backgroundColor: R.colors.vividBlue }}
      />
      <AnimationDot
        {...animationProps}
        transition={{ repeat: Infinity, delay: 0.5, type: "keyframes" }}
        css={{ backgroundColor: R.colors.vividRed }}
      />
    </AnimationContainer>
  );
}
