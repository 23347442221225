export const BONUSX_ACCESS_TOKEN = "bonusXAccessToken";

export const defaultApiConfig = {
  baseOptions: {
    withCredentials: true,
  },
  basePath: `${process.env.REACT_APP_MAIN_URL}/sapi/api/v1`,
  isJsonMime: () => false,
};

export const defaultApiConfig_v1_1 = {
  baseOptions: {
    withCredentials: true,
  },
  basePath: `${process.env.REACT_APP_MAIN_URL}/sapi/api/v1.1`,
  isJsonMime: () => false,
};

export const adminApiConfig = {
  baseOptions: {
    withCredentials: true,
  },
  basePath: `${process.env.REACT_APP_SADM_URL}/sadm/api/v1`,
  isJsonMime: () => false,
};

export const adminApiConfig_v1_1 = {
  baseOptions: {
    withCredentials: true,
  },
  basePath: `${process.env.REACT_APP_SADM_URL}/sadm/api/v1.1`,
  isJsonMime: () => false,
};

export const authApiConfig_v1 = {
  baseOptions: {
    withCredentials: true,
  },
  basePath: `${process.env.REACT_APP_AUTH_URL}/auth/api/v1`,
  isJsonMime: () => false,
};

export const ABILITY_RULES_KEY = "ability-rules";

export const ordinaryForms = ["citizenship", "family", "redditi", "patrimonio"];

export const ROOT_STORE_STORAGE_INDEX = "bonusXState";

export const USER_LOGIN_STORE_KEY = "bonusXUserLogin";

export const beforeUnloadEventName = "beforeunload";

export const BONUSX_WEBSITE_PATH = "https://bonusx.it";

type ExternalPathCollectionKeys =
  | "main"
  | "terms_of_use"
  | "general_conditions_of_service"
  | "privacy_policy"
  | "cookies_policy"
  | "contact_us"
  | "contact_email"
  | "ai_privacy_policy";

export const EXTERNAL_PATH_COLLECTION = new Map<ExternalPathCollectionKeys, string>([
  ["main", BONUSX_WEBSITE_PATH],
  ["terms_of_use", `${BONUSX_WEBSITE_PATH}/termini-di-utilizzo/`],
  ["general_conditions_of_service", `${BONUSX_WEBSITE_PATH}/condizioni-generali-di-servizio/`],
  ["privacy_policy", `${BONUSX_WEBSITE_PATH}/privacy-policy/`],
  ["cookies_policy", `${BONUSX_WEBSITE_PATH}/cookies-policy/`],
  ["contact_us", "https://bonusx.zendesk.com/hc/it"],
  ["contact_email", "mailto:supporto@bonusx.it"],
  ["ai_privacy_policy", `${BONUSX_WEBSITE_PATH}/privacy-policy-assistente-virtuale/`],
]);

export const DOM_READY_EVENT = "DOMContentLoaded";

export const ERROR_MATCH_4XX = /4[0-9]{2}/;
export const ERROR_MATCH_5XX = /5[0-9]{2}/;

export const APP_VERSION = process.env.REACT_APP_VERSION || "unknown";

export const NEXT_URL_PARAM_KEY = "nextURL";

// This one is a fictitious page, not present in the router, is used to automatically redirect to the next URL upon returning from external links.
export const NEXT_URL_PATH = "/next-url";
// Also this one is a fictitious page, and is utilized by the back button in the header in order to navigate back to the previous URL dynamically.
export const PREVIOUS_URL_PATH = "/prev-url";

export const EXTERNAL_LOGIN_PATH = "/external-login";

export const VERIFY_EMAIL_PATH = "/verify-email";

export const DASHBOARD_PATH = "/dashboard";

export const CART_PATH = "/cart";

export const NOTIFICATIONS_PATH = "/notifications";

export const PROFILE_PATH = "/profile";

export const SERVICES_PATH = "/services";

export const REQUESTED_SERVICES_PATH = "/requested-services";

export const ARCHIVE_PATH = "/archive";

export const SETTINGS_PATH = "/settings";

export const INVITE_FRIENDS_PATH = "/invite-friends";

export const PROFILE_COMPLETATION_PATH = "/complete-profile";

export const LOGOUT_ROUTE = "/logout";

export const TOGGLE_BOTTOM_NAVIGATION_DRAWER_ROUTE = "/altro";

export const WELCOME_PATH = "/welcome";

export const LOGIN_PATH = "/login";

export const FORGOT_PASSWORD_PATH = "/forgot-password";

export const HOME_PAGE_PATH = "/services";

export const ROUTES_WITH_MOBILE_APP_BANNER: (string | RegExp)[] = [
  HOME_PAGE_PATH,
  PROFILE_PATH,
  SERVICES_PATH,
  REQUESTED_SERVICES_PATH,
  ARCHIVE_PATH,
  SETTINGS_PATH,
  INVITE_FRIENDS_PATH,
  /^\/services\/\d+$/,
  /^\/requested-services\/\d+$/,
  /^\/requested-services\/\d+\/completed$/,
];

export const ADMIN_BASE_URL = "/admin";
export const ADMIN_PRODUCTS_PATH = `${ADMIN_BASE_URL}/services`;

export const GTM_ONCE_FOR_SESSION_KEY = "gtm_once_for_session";

export const BONUSX_VERIFY_EMAIL_COOKIE = "bonusXVerifyEmail";

export const CLIENT_ID_PARAM_KEY = "ci";

export const MINLENGTH_TO_SEARCH = 2;

export const REACT_APP_SADM_URL = process.env.REACT_APP_SADM_URL;

export const REACT_APP_AUTH_URL = process.env.REACT_APP_AUTH_URL;

export const NODE_ENV = process.env.NODE_ENV;

export const BONUSX_CMS_URL = process.env.REACT_APP_CMS_URL;

export const BONUSX_CMS_TOKEN = process.env.REACT_APP_BONUSX_CMS_TOKEN || "";

export const GA_ID = process.env.REACT_APP_GA_ID || "";

export enum BonusXCustomResponseHeader {
  TotalCount = "x-total-count",
}

export const DEFAULT_DATE_LOCALE = "it";

export const DEFAULT_DATETIME_FORMAT = "DD/MM HH:mm";
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const ZENDESK_AUTH_PATH = process.env.REACT_APP_ZENDESK_AUTH_PATH;

const UPLOAD_SIZE_LIMIT = 18 * 1024 * 1024;

export const PICKER_DOCUMENT_UPLOAD_MAX_SIZE: number = UPLOAD_SIZE_LIMIT;
export const ALL_FILE_TYPES_ACCEPTED: undefined = undefined;
export const SIGNATURE_UPLOAD_MAX_SIZE: number = UPLOAD_SIZE_LIMIT;
export const CERTIFICATE_UPLOAD_MAX_SIZE: number = UPLOAD_SIZE_LIMIT;

export enum TrustpilotConfig {
  templateId = "53aa8807dec7e10d38f59f32",
  businessUnitId = "603e59a7cc01ad000135755e",
  locale = "it-IT",
}

export const DEFAULT_PAYMENT_METHOD = "creditcard";

export const FEATURE_FLAG_API_KEY = process.env.REACT_APP_FEATURE_FLAG_API_KEY ?? "";

export const MEILISEARCH_HOST = process.env.REACT_APP_MEILISEARCH_HOST ?? "";
export const MEILISEARCH_API_KEY = process.env.REACT_APP_MEILISEARCH_KEY ?? "";

export const BONUS_ON_DEMAND_SURVEY_LINK =
  process.env.BONUS_ON_DEMAND_SURVEY_LINK ?? "https://simplifai-ted.typeform.com/to/f0fkBBN0";
export const UNIQUE_IDENTIFIER_FIELD_NAME = "unique_identifier";
export const UNIQUE_IDENTIFIER_FIELD_DEFAULT_VALUE = "";
export const PRODUCT_MISSING_NAME_DEFAULT_VALUE = "Unavailable";

export const PACKAGE_NAME = "bx-feapp-platform";

export const ZERO_DIGIT = 0;
