import { PropsWithChildren, useMemo, useReducer } from "react";
import { ChatbotContext } from "../chatbot.context";
import { chatbotInitialState, chatbotReducer } from "../lib/reducer/chatbot.reducer";

export function ChatbotProvider({ children }: Readonly<PropsWithChildren<{}>>): JSX.Element {
  const [state, dispatch] = useReducer(chatbotReducer, chatbotInitialState);

  const value = useMemo(
    function memoizeChatbotProviderValue() {
      return { state, dispatch };
    },
    [state, dispatch]
  );

  return <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>;
}
