import { ThemeProvider } from "@bonusx-global/bx-design-system";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { OpenFeatureProvider } from "@openfeature/react-sdk";
import { Provider as JotaiProvider } from "jotai";
import React, { lazy } from "react";
import { Provider } from "react-redux";
import { ChatbotProvider } from "./components/Chatbot/contexts/chatbot-provider";
import { InitializeApp } from "./components/InitializeApp";
import SuspenceWrapper from "./components/SuspenseWrapper";
import { WindowBeforeUnload } from "./components/WindowBeforeUnload";
import { ErrorHttpProvider } from "./contexts/ErrorHttpContext/providers/ErrorHttpProvider";
import store from "./store";
import citizenTheme from "./styles/citizenTheme";

const App = lazy(() => import("./App"));

export function ProvideAppEssentials() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <JotaiProvider>
          <SuspenceWrapper>
            <OpenFeatureProvider suspend={true}>
              <ErrorHttpProvider>
                <ChatbotProvider>
                  <InitializeApp />
                  <WindowBeforeUnload />
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={citizenTheme}>
                      <App />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </ChatbotProvider>
              </ErrorHttpProvider>
            </OpenFeatureProvider>
          </SuspenceWrapper>
        </JotaiProvider>
      </Provider>
    </React.StrictMode>
  );
}
