import { Ability } from "@casl/ability";
import axios, { AxiosResponse } from "axios";
import { Account, AccountApi, CitizenApi } from "bonusx-api-main-manager";
import isEqual from "lodash/isEqual";
import { createStore } from "redux";
import { BehaviorSubject, Subject, from, of } from "rxjs";
import { catchError, distinctUntilChanged, map, mergeMap, tap, throttleTime } from "rxjs/operators";
import {
  ABILITY_RULES_KEY,
  BONUSX_ACCESS_TOKEN,
  ROOT_STORE_STORAGE_INDEX,
  defaultApiConfig,
  ordinaryForms,
} from "./common/env";
import { QuestionnaireName } from "./enums/QuestionnaireName.enum";
import rootReducer from "./reducers";
import { MainSteps } from "./services/MainSteps";
import { globalStickyError$ } from "./stores/globalStickyError";
import { questionnaireSubject$ } from "./stores/questionnaireSubject";
import { getCookie } from "./utils/getCookie";
import { doLogout } from "./utils/logout";
import { serializeForDiffCheck } from "./utils/serializeForDiffCheck";

const citizenApi = new CitizenApi(defaultApiConfig);
const accountApi = new AccountApi(defaultApiConfig, undefined, axios);

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const saveQuestionnaireLocally = (prevState: RootStoreModule.State) => {
  const activeSteps = MainSteps.get();

  let newState = { ...prevState, activeStepName: activeSteps.currentForm, currentForm: activeSteps.currentForm };

  if (ordinaryForms.includes(prevState.currentForm)) MainSteps.set({ ...prevState });

  localStorage.setItem(prevState?.keySaveState || ROOT_STORE_STORAGE_INDEX, JSON.stringify(newState));

  return newState;
};

const questionnaiereIsValid = async (prevState: RootStoreModule.State) => {
  const { formValues, currentForm } = { ...prevState };

  const accessToken = getCookie(BONUSX_ACCESS_TOKEN);
  const rules = JSON.parse(localStorage.getItem(ABILITY_RULES_KEY) || "[]");
  const abilityRules = new Ability(rules);

  if (!accessToken) return Promise.reject("Login required");
  if (abilityRules.cannot("update", "Account")) return Promise.reject("Insufficient permission");

  const keysFormValues = Object.keys(formValues) || [];

  if (!Math.min(keysFormValues.length, currentForm?.length)) return Promise.reject("Form not valid");

  return Promise.resolve(prevState);
};

const persistQuestionnaireRemotly = async (prevState: RootStoreModule.State) => {
  const { formValues, stepHistory } = { ...prevState };

  const activeSteps = MainSteps.get();

  return await citizenApi.updateQuestionnaireFields({
    questionnaire: {
      formValues,
      stepHistory,
      currentForm: activeSteps.currentForm || "welcome",
      activeStepName: activeSteps.activeStepName,
    },
  });
};

export async function saveQuestionnaireDataToApi(
  prevState: RootStoreModule.State
): Promise<void | AxiosResponse<Account>> {
  const { formValues, currentForm } = { ...prevState };

  const mainActivity = formValues["AttivitaPrincipale"];
  const city = formValues["applicant_PosizioneResidenza"];

  if (!mainActivity || !city || currentForm !== QuestionnaireName.Citizenship) return Promise.resolve();

  const editProfilePayload = {
    mainActivity,
    city,
  };

  return await accountApi.editProfile(editProfilePayload);
}

const subscibeToQuestionnairePersist = (questionnaireSubject$: Subject<RootStoreModule.State>) => {
  console.debug("Questionnaire start listen");

  const handle401Error = (err: any) => {
    if (err && err.response?.status === 401) {
      doLogout().finally(() => {
        globalStickyError$.next({ message: "Sessione scaduta" });
        window.location.href = "/welcome";
      });
      throw err;
    }

    return of(err);
  };

  const questionnairePersist$ = questionnaireSubject$.pipe(
    throttleTime(500, undefined, { trailing: true }), // serve a prevenire dei flood di modifiche di stato che arrivano da redux
    distinctUntilChanged(
      (
        previous,
        current // evita di salvare lo stesso stato 2 volte di fila
      ) => isEqual(serializeForDiffCheck(previous.formValues), serializeForDiffCheck(current.formValues))
    ),
    map(saveQuestionnaireLocally), // salva il questionario in locale
    mergeMap(async (data: any) => {
      // controlla la validata del questionario
      return await questionnaiereIsValid(data);
    }),
    tap(() => showSavingsIcon$.next(true)), // mostra l'icona di salvataggio
    mergeMap((data: any) => from(Promise.all([persistQuestionnaireRemotly(data), saveQuestionnaireDataToApi(data)]))),
    catchError(handle401Error), // Handle 401 error and other errors here as a new step in the pipeline
    catchError((err) => {
      showSavingsIcon$.next(false); // nasconde l'icona di salvataggio in caso di errore
      return of(err); // ritorna un observable al posto di un errore per non interrompere la subscription
    }),
    tap(() => setTimeout(() => showSavingsIcon$.next(false), 500)) // nasconde l'icona di salvataggio
  );

  questionnairePersist$.subscribe({
    next: (data) => console.debug("Questionnaire response:", data),
    error: (error) => {
      console.error("Questionnaire error:", error);
      subscibeToQuestionnairePersist(questionnaireSubject$);
    },
    complete: () => {
      console.debug("Questionnaire stop listen");
      subscibeToQuestionnairePersist(questionnaireSubject$);
    },
  });
};

export const showSavingsIcon$ = new BehaviorSubject(false);

subscibeToQuestionnairePersist(questionnaireSubject$);

store.subscribe(() => questionnaireSubject$.next(store.getState().updates));

export default store;
